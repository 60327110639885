/* src/ChatInterface.css */

.ChatInterface {
    display: flex;
    background-color: #ffffff;
    color: #01636E;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    
    .sidemenu {
    width: 260px;
    padding: 10px;
    background-color: #eeeeee;
    display: flex;
    flex-direction: column;
    }

    .side-menu-button {
    padding: 15px;
    border-radius: 5px;
    text-align: left;
    background-color: #ffffff;
    }

    .side-menu-button:hover {
    background-color: rgb(255, 255, 255, 0.05);
    border: 1px solid #01636E;
    }

    .side-menu-button span{
    padding-left: 6px;
    padding-right: 12px;
    }

    .chatbox {
    flex:1;
    background-color: #ffffff;
    position: relative;
    height: 100vh; /* Full height of the viewport */
    display: flex;
    flex-direction: column;
    }

    /* Style for the chat banner */
    .chat-banner {
    position: relative; /* Set relative positioning so the button is positioned relative to the banner */
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 60px;
    }
  
    /* Style for the banner image (optional) */
    .banner-image {
    height: 30px;
    }
  

    /* Style for the logout button */
    .logout-button {
    position: absolute;  /* Position it inside the chat-banner */
    top: 50%;            /* Vertically center */
    right: 20px;         /* Align to the right corner */
    transform: translateY(-50%); /* Center vertically */
    background-color: #01636E;
    color: white;
    padding: 8px 12px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
    }
  
    .logout-button:hover {
        background-color: #05272b;
    }

    .chat-log {
    flex:1;
    overflow-y: auto;
    padding: 20px;
    height: calc(100% - 200px);
    background-color: #f9f9f9;
    }

    .chat-input-holder {
    background-color: #f9f9f9; /* Solid color with no transparency */
    padding: 12px;
    bottom: 0;
    left: 0;
    right: 0;
    height: 40px;
    }


    .chat-input-holder form {
    display: flex;
    align-items: center;
    flex-direction: row;
    }

    .chat-input-textarea {
    flex-grow: 1;
    padding: 12px;
    color: #01636E !important;
    font-size: 1.25em;
    background-color: #eeeeee;
    border-radius: 40px;
    border: none;
    margin-right: 12px;
    outline: none;
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.25);
    }

    .chat-submit-button {
    background-color: #01636E;
    color: white;
    border: none;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: background-color 0.3s ease;
    }

    .chat-submit-button:hover {
    background-color: #05272b;
    }

    .chat-submit-button svg {
    width: 24px;
    height: 24px;
    }


    .chat-message-center {
    max-width: 640px;
    margin-left: auto;
    margin-right: auto;
    padding: 12px;
    padding-left: 24px;
    padding-right: 24px;
    display: flex;
    flex-direction: column; /* Stack the flight cards in a single column */
    gap: 10px; /* Optional: Adds spacing between the cards */
    }

    .message-container {
    display: flex;
    align-items: flex-start; /* Aligns avatar and message properly */
    margin-bottom: 10px; /* Adds space between messages */
    }

    .message-container.user-message {
        justify-content: flex-end; /* Aligns the user message to the right */
    }

    .avatar {
        width: 40px;
        height: 40px;
        margin-right: 10px; /* Space between avatar and message */
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .avatar img {
        width: 100%;
        height: 100%;
        object-fit: cover; /* Ensures the avatar fits properly */
    }

    .message {
        display: inline-block; /* Allows the message box to shrink/expand with content */
        padding: 12px;
        border-radius: 20px;
        max-width: 80%; /* Ensures message doesn't stretch too much */
        word-wrap: break-word; /* Ensures long words wrap properly */
        white-space: pre-wrap; /* Respects line breaks */
    }
    
    .user-message {
        padding-left:5px;
        background-color: #ffffff;
        border-radius: 20px;
        border: 3px solid #01636E;
        word-wrap: break-word;
        box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.1);
        margin-left: auto; /* Ensures the user message stays on the right */
        max-width:80%;
    }
    
    .user-message .message {
        color: #01636E; /* Proper color format */
        text-align: right; /* Aligns text inside the box to the right */
        white-space: pre-wrap; /* Ensures that text breaks properly and respects line breaks */
        max-width: 100%;
    }

    
    .chat-interface {
    display: flex;
    flex-direction: column;
    height: 100%;
    }

    .message-list {
    flex-grow: 1;
    overflow-y: auto;
    padding: 1rem;
    }

    .message {
    margin-bottom: 0.5rem;
    }

    .message-form {
    display: flex;
    padding: 1rem;
    }

    .message-form input {
    flex-grow: 1;
    margin-right: 0.5rem;
    }

    .chat-list {
    margin-top: 20px;
    overflow-y: auto;
    flex-grow: 1;
    }

    .chat-card {
    padding: 10px;
    margin-bottom: 5px;
    background-color: #ffffff;
    border-radius: 5px;
    
    cursor: pointer;
    transition: background-color 0.3s ease;
    }

    .chat-card:hover {
    background-color: #dddddd;
    }

    .chat-card.active {
    background-color: #cccccc;
    font-weight: bold;
    }
}