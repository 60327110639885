.flight-search-container {
    border: 1px solid #ccc;
    padding: 5px;
    border-radius: 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 500px;
    align-items: center;
    border-radius: 10px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    font-family: 'Oswald', sans-serif;
  }
  
  .search-fields {
    display: flex;
    flex-direction: row; /* Stack multiple flight-info rows vertically */
    flex-grow: 1; /* Allows flight-info-wrapper to take more space */
    flex-basis: 0; /* Basis ensures that flex-grow works properly */
    padding-left: 20px;
    padding-right: 20px;
    justify-content: space-between;
  }
  
  .field {
    width: 100%;
    flex:1;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  
  .field label {
    padding-right: 5px;
    color: #aaa;
    font-size: 12px;
    align-items: bottom;
  }
  
