.flight-card {
    border: 1px solid #ccc;
    padding: 5px;
    border-radius: 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 500px;
    align-items: center; /* Align items vertically in the center */
    font-family: 'Oswald', sans-serif;
  }

  .flight-info-wrapper {
    display: flex;
    flex-direction: column; /* Stack multiple flight-info rows vertically */
    flex-grow: 2; /* Allows flight-info-wrapper to take more space */
    flex-basis: 0; /* Basis ensures that flex-grow works properly */
    padding-left: 20px;
    padding-right: 20px;
    border-right: 1px solid #ddd; /* Optional: Add a separator between flight-info elements */
  }
  
  .airline-logo {
    width: 40px;
    height: auto;
  }

  .flight-info {
    display: flex;
    justify-content: space-between; /* Distribute items evenly across the row */
    align-items: center; /* Align items vertically in the center */
    padding: 10px; /* Apply uniform padding inside each flight-info */
  }
  
  .departure, .arrival {
    display: flex;
    flex-direction: column; /* Stack airport and time vertically */
    align-items: right; /* Align items to the left */
  }
  
  .duration-arrow {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1px; /* Reduce space between duration, arrow, and stops-info */
  }
  
  .duration {
    font-size: 14px;
    margin-bottom: 2px; /* Reduce margin to reduce height */
  }
  
  .arrow {
    width: 90px; /* Adjust length of the arrow */
    height: 2px;
    background-color: #333;
    position: relative;
  }
  
  .stops-info {
    font-size: 12px;
    margin-top: 2px; /* Reduce margin for stops-info */
  }


  .flight-time {
    font-size: 28px;
    align-items: center;
  }

  .airport {
    font-size: 16px;
    align-items: center;
  }
  
  .arrow::after {
    content: '';
    position: absolute;
    right: 0;
    top: -5px;
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;
    border-left: 10px solid #333; /* Color of the arrowhead */
  }
  
  .time {
    font-size: 14px;
    color: #555;
  }
  
  .airlines {
    font-size: 14px;
    color: #777;
    text-align: center;
    padding-right: 30px;
  }

  .price-column {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-grow: 1; /* Allows the price column to take less space */
    padding-left: 20px;
    flex-basis: 0; /* Basis ensures that flex-grow works properly */
  }
  
  .price {
    font-size: 20px;
    font-weight: bold;
    text-align: center;
  }