/* Authentication.css */

html, body {
    font-family: 'Oswald', sans-serif;
    background: linear-gradient(#008080, #060633);
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    margin: 0;
    box-sizing: border-box;
    overflow: hidden; /* Prevent scrolling */
  }

  /* Styling for the image */
  .voia-logo {
    max-width: 200px;
    padding:40px;
  }
  
  .auth-box {
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 30px;
    border-radius: 10px;
    text-align: center;
    align-items: center;
    width: 100%;
    height: 50%;
    max-width: 400px; /* Max width for large screens */
    box-sizing: border-box; /* Ensure padding is included in width */
    overflow: hidden; /* Prevents scroll inside auth-box */
  }    
  
  form {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  
  input {
    padding: 12px;
    margin-bottom: 10px;
    border-radius: 5px;
    border: 1px solid #ddd;
    font-size: 14px;
    box-sizing: border-box;
  }
  
  button {
    padding: 12px;
    background-color: #FF6F61;
    color: white;
    font-size: 14px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  button:hover {
    background-color: #e99b94;
  }

  .error {
    color: red;
    margin-top: 10px;
    font-size: 14px;
  }
  
  .toggle-link {
    margin-top: 15px;
    font-size: 14px;
    color: #ffffff;
    cursor: pointer;
  }
  
  .toggle-link span {
    color: #dddddd;
    font-weight: bold;
    cursor: pointer;
  }
  
  .toggle-link span:hover {
    text-decoration: underline;
    color: #e99b94;
  }

  /* Media Query for smaller screens */
  @media (max-width: 600px) {
    .auth-box {
        padding: 20px; /* Reduce padding on small screens */
        width: 90%; /* Full width on small screens */
    }

    /* Ensure the logo doesn't take too much space */
    .voia-logo {
        max-width: 150px;
        padding: 20px;
    } 

  }  
    