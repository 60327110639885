/* src/LandingPage.css */

.container {
    font-family: 'Oswald', sans-serif;
    text-align: center;
    color: #333;
    padding: 0;
    margin: 0;
}

.wrapper {
    display: flex;
    flex-direction: column; /* Ensure that child elements stack vertically */
    align-items: center; /* Center content horizontally (optional) */
    width: 100%;
    background: linear-gradient(#008080, #060633);
   
}

.header {
    padding: 20px 20px;
    border-bottom: 2px solid #FF6F61;
    width:100%;
}
.logo {
    height: 50px;
}

.heroSection {
    padding: 50px 0;
    color: #fff;
} 

.heroContent {
    padding-top: 40px;
    padding-bottom: 40px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    margin: 0;
    gap: 50px; /* Adjust the gap between the text and image to bring them closer */
}

.textContainer {
    flex: 1;
    padding-right: 0px;
    margin-top: 0px;
}
  
.imageContainer {
    flex: 1;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-left: 0;
    margin-top: 30px;
} 

.heroImage {
    width: 90%;
    margin: 0;
}

/* Base styles for larger screens (e.g., desktop) */
.heroTitle {
    font-size: 5vw; /* Scales with the viewport width */
    line-height: 1.5;
    margin-left: auto; /* Align to the right */
    max-width: 700px;
    background: linear-gradient(90deg, #FFFFFF, #FF6F61);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    text-align: right;
    margin-bottom: 10px;
    margin-top: 10px;
}

.heroSubtitle {
    font-size: 1.5vw; /* Scales with the viewport width */
    color: #ffffff;
    max-width: 400px;
    margin-left: auto; /* Align to the right */
    margin-top: 10px;
    text-align: right;
}


.buttonContainer {
    display: flex;
    justify-content: flex-end;
    align-items: right;
}

.navList {
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0;
}

.navItem {
    margin: 0 10px;
}

.navLink {
    text-decoration: none;
    color: #333;
}

.aboutSection {
    padding: 50px 20px;
}

.sectionTitle {
    font-size: 36px;
}

.sectionTitleWhite {
    font-size: 36px;
    color: #ffffff;
}

.sectionText {
    font-size: 18px;
    color: #666;
    max-width: 800px;
    margin: 20px auto;
}

.featuresSection {
    padding: 50px 20px;
    background: linear-gradient(to left, #008080, #060633);
}

.featureGrid {
    display: flex;
    justify-content: space-around;
    margin-top: 30px;
}

.featureCard {
    width: 28%;
    background-color: #fff;
    padding: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border: 3px solid #FF6F61;
    border-radius: 15px;
}

.stayUpdatedSection {
    padding: 50px 20px;
}

.contactForm {
    display: flex;
    justify-content: center; /* Center the items horizontally */
    align-items: center;     /* Align items vertically in the center */
    gap: 10px;               /* Optional: Adds space between the input and button */
}

.inputField {
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ccc;
    width: 300px;            /* Set a width for the input field */
}

.joinWaitingListButton {
    padding: 10px 20px;
    background-color: #FF6F61;
    color: #fff;
    border: none;
    cursor: pointer;
    border-radius: 5px;
}